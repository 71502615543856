import {Author, Hero, Iframe, ImageWithCaption, Layout, Text} from "components/BlogLayout";
import SEO from "components/seo";
import React from "react";
import {useArticlesData} from "shared/Hooks/Articles/useArticlesData";
import {ARTICLE_18} from "shared/Types/articles";

const Article_18 = () => {
  const data = useArticlesData(ARTICLE_18);

  return (
    <Layout>
      <SEO title={data.SEO_title} description={data.seo} image={data.image}/>
      <Hero data={data}/>
      <Text data={data.text.first}/>
      <Text data={data.text.second}/>
      <Text data={data.text.third}/>
      <ImageWithCaption data={data.imagesWithCaption.first}/>
      <Text data={data.text.fourth}/>
      <ImageWithCaption data={data.imagesWithCaption.second}/>
      <Text data={data.text.fifth}/>
      <ImageWithCaption data={data.imagesWithCaption.third}/>
      <Text data={data.text.sixth}/>
      <ImageWithCaption data={data.imagesWithCaption.fourth}/>
      <Text data={data.text.seventh}/>
      <ImageWithCaption data={data.imagesWithCaption.fifth}/>
      <Text data={data.text.eight}/>
      <Iframe src={data.iframe.first.src}/>
      <Text data={data.text.ninth}/>
      <ImageWithCaption data={data.imagesWithCaption.sixth}/>
      <Text data={data.text.tenth}/>
      <ImageWithCaption data={data.imagesWithCaption.seventh}/>
      <Text data={data.text.eleventh}/>
      <Iframe src={data.iframe.second.src}/>
      <Text data={data.text.twelfth}/>
      <ImageWithCaption data={data.imagesWithCaption.eight}/>
      <Text data={data.text.thirteenth}/>
      <ImageWithCaption data={data.imagesWithCaption.ninth}/>
      <Text data={data.text.fourteenth}/>
      <ImageWithCaption data={data.imagesWithCaption.tenth}/>
      <Text data={data.text.fifteenth}/>
      <ImageWithCaption data={data.imagesWithCaption.eleventh}/>
      <Text data={data.text.sixteenth}/>
      <Author data={data.authors}/>
    </Layout>
  );
};

export default Article_18;

